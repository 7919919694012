/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Navbar from "../../components/BottomNavigation/Navbar";
import PageTitle from "../../components/PageTitle/PageTitle";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getTodaysOrders } from "../../redux/actions/actions";
import { updateOrderStatus } from "../../services/kitchen";

function HomePage() {
  const dispatch = useDispatch();
  const { todaysOrders, selectedKitchen } = useSelector(state => state?.reducer, shallowEqual);
  const [selectedTime, setSelectedTime] = useState("breakfast");
  const [toggleStockCheck, setToggleStockCheck] = useState(false);
  const [activeOrderList, setActiveOrderList] = useState();
  const [stockItems, setStockItems] = useState([
    {
      id: 1,
      name: "Semolina",
      quantity: "500g",
      status: null,
      placeOrderText: "",
      orderPlaced: false,
    },
    {
      id: 2,
      name: "Onions",
      quantity: "2kg",
      status: null,
      placeOrderText: "",
      orderPlaced: false,
    },
    {
      id: 3,
      name: "Ginger",
      quantity: "100g",
      status: null,
      placeOrderText: "",
      orderPlaced: false,
    },
  ]);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: true
      },
      "google_translate_element"
    );

    // Hide Google Translate bar
    const googleTranslateBar = document.querySelector(".skiptranslate");
    if (googleTranslateBar) {
      googleTranslateBar.style.display = "none";
    }
  };

  const markForDelivery = async () => {
    const res = await updateOrderStatus({ orderIds: activeOrderList?.orderIds, updatedData: { status: 'food-prepared' } });
  }

  useEffect(() => {
    if (toggleStockCheck) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [toggleStockCheck]);

  useEffect(() => {
    dispatch(getTodaysOrders());
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  useEffect(() => {
    setActiveOrderList(todaysOrders?.filter(d => d?._id === selectedTime)[0])
  }, [selectedTime]);

  return (
    <>
      <div className="h-screen px-3 py-4 pb-20" id="google_translate_element">
        <PageTitle title={"Dashboard"} />

        <div className="flex items-center justify-between mb-[2rem]">
          <h4 className="text-blackAccent font-semibold text-[16px]">
            {new Date().toLocaleDateString()} - Orders
          </h4>
          <button onClick={() => { }}>Odia</button>
        </div>
        <div>
          <div className="mb-2">
            {[
              {
                name: "Breakfast",
                value: "breakfast",
              },
              {
                name: "Lunch",
                value: "lunch",
              },
              {
                name: "Dinner",
                value: "dinner",
              },
            ].map((item, index) => (
              <button
                key={index + item?.value}
                onClick={() => setSelectedTime(item?.value)}
                className={`${item.value === selectedTime
                  ? "w-[96px] mr-4 border-[2px] h-[42px] m-auto mb-4 rounded-md text-blackAccent text-[1rem] border-orangeAccent bg-[#FFF4F0]"
                  : "w-[96px] mr-4  h-[42px] m-auto mb-4 rounded-md text-blackAccent text-[1rem] border-blackAccent"
                  }`}
              >
                {item.name}
              </button>
            ))}
          </div>

          <div className="my-2 bg-grey-50 p-5 rounded-xl">
            {
              activeOrderList?.meals?.map((data, index) => (
                <span className="text-[18px] text-[#343741] font-medium mr-1 capitalize" key={data?.mealName + index}>
                  {data?.mealName},
                </span>
              ))
            }

            {
              activeOrderList?.orderIds?.length ?
                <>
                  <p className="text-16 text-[#343741] font-[700] mt-4">No. of orders :{activeOrderList?.orderIds?.length}</p>
                  <div className="flex flex-row items-center justify-end mt-4">
                    {/* <button
                      className="pr-2 flex items-center py-1 text-orangeAccent text-14 font-semibold"
                      onClick={() => setToggleStockCheck(true)}
                    >
                      Stock Check{" "}
                      <span class="material-symbols-rounded">chevron_right</span>
                    </button> */}
                    {activeOrderList?.orderIds?.every(d => d?.status !== 'food-prepared') ?
                      <button className="bg-[#FFF4F0] text-orangeAccent text-14 font-[500] px-4 py-2 rounded-md border-2 border-orangeAccent" onClick={() => markForDelivery()}>
                        Mark For Delivery
                      </button> : <p className="text-center w-full text-grey-300">Order Marked for Delivery</p>
                    }
                  </div>
                </> : null
            }

          </div>
        </div>
      </div>
      <Navbar />

      {/* {toggleStockCheck && (
        <div className="fixed top-0 left-0 w-[100vw] h-[100vh] bg-[rgba(0,0,0,0.5)] z-10"></div>
      )}

      {toggleStockCheck && (
        <div className="fixed bottom-0 left-0 w-[100vw] h-[70vh] bg-[white] z-20 rounded-t-2xl p-8">
          <div className="relative mb-6">
            <h1 className="text-[1rem] text-[#343434] font-[600] text-center">
              Stock Check
            </h1>
            <button
              className="px-4 py-2 flex items-center ml-auto text-orangeAccent absolute right-0 top-[-8px] "
              onClick={() => setToggleStockCheck(false)}
            >
              <span class="material-symbols-rounded ">close</span>
            </button>
          </div>

          <div className="flex items-center justify-between">
            <p className="flex items-center text-[#343741] text-[14px] font-[500]">
              <span class="material-symbols-rounded mr-1 text-orangeAccent">
                restaurant
              </span>{" "}
              Upma & Chutney
            </p>

            <p className="flex items-center text-[#343741] text-[14px] font-[500]">
              <span class="material-symbols-rounded mr-1 text-orangeAccent">
                groups
              </span>{" "}
              20 People
            </p>
          </div>

          <div className="w-[100%] h-[0.125rem] bg-[#F0F0F0] mt-4 mb-6"></div>

          {stockItems?.map((stk, index) => (
            <div className=" mb-4">
              <div className="flex items-center justify-between">
                <div>
                  <h1 className="text-[1rem] text-[#343741] font-[500]">
                    {stk.name}
                  </h1>
                  <h2 className="text-[1rem] text-[#707070] font-[500]">
                    Required : {stk.quantity}
                  </h2>
                </div>
                <div>
                  {stk.status === null ? (
                    <div className="flex items-center gap-x-2">
                      <button
                        className="bg-orangeAccent text-[white] rounded-md flex p-1"
                        onClick={(e) =>
                          setStockItems(
                            stockItems.map((cur, curIdx) =>
                              cur.id === stk.id
                                ? {
                                  ...cur,
                                  status: "available",
                                }
                                : cur
                            )
                          )
                        }
                      >
                        <span class="material-symbols-rounded">done</span>
                      </button>
                      <button
                        className="bg-[#FFBFAB] text-[white] rounded-md flex p-1"
                        onClick={(e) =>
                          setStockItems(
                            stockItems.map((cur, curIdx) =>
                              cur.id === stk.id
                                ? {
                                  ...cur,
                                  status: "unavailable",
                                }
                                : cur
                            )
                          )
                        }
                      >
                        <span class="material-symbols-rounded">close</span>
                      </button>
                    </div>
                  ) : stk.status === "available" ? (
                    <p className="text-orangeAccent font-[400] text-[14px]">
                      Confirmed
                    </p>
                  ) : (
                    <p className="text-[#707070] font-[400] text-[14px]">
                      Not Available
                    </p>
                  )}
                </div>
              </div>
              {stk?.status === "unavailable" && (
                <div className="border-2 border-orangeAccent mt-2 flex items-center rounded-sm">
                  <input
                    className="w-[70%] p-2 border-none outline-none bg-transparent"
                    placeholder="Place an order, eg : 500g"
                    value={stk.placeOrderText}
                    onChange={(e) =>
                      setStockItems(
                        stockItems.map((cur, curIdx) =>
                          cur.id === stk.id
                            ? {
                              ...cur,
                              placeOrderText: e.target.value,
                            }
                            : cur
                        )
                      )
                    }
                    disabled={stk.orderPlaced}
                  />
                  <button
                    className="w-[30%] flex-2 py-2 text-orangeAccent text-[14px] font-[600] "
                    onClick={(e) =>
                      setStockItems(
                        stockItems.map((cur, curIdx) =>
                          cur.id === stk.id
                            ? {
                              ...cur,
                              orderPlaced: true,
                            }
                            : cur
                        )
                      )
                    }
                    disabled={stk.orderPlaced}
                  >
                    {stk.orderPlaced ? "Order Placed !" : "Send"}
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )} */}
    </>
  );
}

export default HomePage;
