import React from "react";

function PageTitle({ title }) {
  return (
    <div className="mb-8">
      <h2
        className="text-[1.75rem] text-center font-semibold text-blackAccent
      "
      >
        {title}
      </h2>
      <div className="w-[120px] h-[6px] bg-orangeAccent m-auto rounded-2xl"></div>
    </div>
  );
}

export default PageTitle;
