import React from "react";

function SolidButton({ text, onClick, disabled = false, classNames }) {
  return (
    <button
      className={`bg-orange-10 w-[100%] h-[48px] rounded-full text-white font-semibold my-4 ${classNames}`}
      onClick={onClick}
      disabled={false}
    >
      {text}
    </button>
  );
}

export default SolidButton;
