const initialState = {
    profile: {
        name: null,
        _id: null
    },
    selectedPG: null,
    isLoading: false,
    todaysOrders: [],
    selectedKitchen: null,
    kitchensList: null
}

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'FETCH_USER_PROFILE': {
            return {
                ...state,
                profile: {
                    name: payload?.name,
                    _id: payload?._id
                }
            }
        }

        case 'SET_PG': {
            return {
                ...state,
                selectedPG: payload
            }
        }

        case 'SET_TOKEN': {
            return {
                ...state,
                token: payload
            }
        }

        case 'TOGGLE_LOADING': {
            return {
                ...state,
                isLoading: payload
            }
        }

        case 'SET_TODAYS_ORDERS': {
            return {
                ...state,
                todaysOrders: payload
            }
        }

        case 'SET_KITCHEN': {
            return {
                ...state,
                selectedKitchen: payload
            }
        }

        case 'SET_KITCHEN_LIST': {
            return {
                ...state,
                kitchensList: payload
            }
        }

        default:
            return state;
    }
}

export default reducer;