// import { checkOptStatus, fetchMealList, fetchMonthlyMealSummary, fetchTodaysMeal, fetchUserMeals, optAMeal } from "../../services/api/meals";

import { fetchAllKitchens, fetchTodaysOrders } from "../../services/kitchen";

const SET_TODAYS_ORDERS = 'SET_TODAYS_ORDERS';
const SET_KITCHEN = 'SET_KITCHEN';
const SET_KITCHEN_LIST = 'SET_KITCHEN_LIST';

export const setKitchen = (type, payload) => {
    return async (dispatch) => {
        dispatch({
            type: type,
            payload: payload
        })
    }
}

export const setTodaysOrders = (payload) => {
    return async (dispatch) => {
        dispatch({
            type: SET_TODAYS_ORDERS,
            payload: payload
        })
    }
}

export const getKitchens = () => {
    return async (dispatch) => {
        const {data} = await fetchAllKitchens();
        await dispatch(setKitchen(SET_KITCHEN_LIST, data?.data));

        return data;
    }
}

export const getTodaysOrders = () => async (dispatch) => {
    const { data: { data } } = await fetchTodaysOrders(localStorage.getItem('selected_kitchen'));
    await dispatch(setTodaysOrders(data));
}