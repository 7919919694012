import axios from "axios";

// BASE URL - LOCAL & CLOUD
const baseURL = process.env.REACT_APP_API_HOST;

// URL function returning the url
function URL(url) {
    return `${baseURL}/${url}`;
}

export function getCookie(name) {
    function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : null;
}

export const refreshToken = async (id) => {
    var data = {
      id,
    };
  
    var config = {
      method: "post",
      url: `${baseURL}/user/refreshToken`,
      headers: {
        "Access-Control-Request-Headers": "*",
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(config);
  };

export const callBackend = async (method, url, data = {}, additionalHeader) => {
    let authToken = getCookie("auth_token");
    
    switch (method) {
        case "GET": {
            let config = {
                method: "get",
                url: URL(url),
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            };
            return await axios(config);
        }

        case "POST": {
            let config = {
                method: "post",
                url: URL(url),
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                    ...additionalHeader,
                },
                data,
            };

            return await axios(config);
        }

        case "DELETE": {
            let config = {
                method: "delete",
                url: URL(url),
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                data,
            };

            return await axios(config);
        }
        case "PUT": {
            let config = {
                method: "put",
                url: URL(url),
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                data,
            };

            return await axios(config);
        }
        default:
            break;
    }
};