import React from "react";
import { NavLink } from "react-router-dom";

function Navbar() {
  return (
    <div
      className="fixed
    bg-blackAccent w-[100%] max-w-[520px]
    bottom-0 py-4 px-24 flex items-center justify-between z-5
    "
    >
      <NavLink
        to={"/home"}
        className={({ isActive, isPending }) =>
          isActive
            ? "text-orangeAccent flex flex-col items-center"
            : "text-white flex flex-col items-center"
        }
      >
        <span className="material-symbols-rounded">home</span>
        <h6 className="text-xs">Home</h6>
      </NavLink>
      {/* <NavLink
        to={"/stock"}
        className={({ isActive, isPending }) =>
          isActive
            ? "text-orangeAccent flex flex-col items-center"
            : "text-white flex flex-col items-center"
        }
      >
        <span class="material-symbols-rounded">lists</span>
        <h6 className="text-xs">Stock</h6>
      </NavLink> */}

      <NavLink
        to={"/profile"}
        className={({ isActive, isPending }) =>
          isActive
            ? "text-orangeAccent flex flex-col items-center"
            : "text-white flex flex-col items-center"
        }
      >
        <span className="material-symbols-rounded">person</span>
        <h6 className="text-xs">Profile</h6>
      </NavLink>
    </div>
  );
}

export default Navbar;
