import React, { useEffect, useState } from "react";
import cooking from "../../assets/images/Cooking.png";
import { navigate, useNavigate } from "react-router-dom";
import SolidButton from "../../components/Buttons/SolidButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setKitchen, getKitchens } from "../../redux/actions/actions";

function LandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { kitchensList } = useSelector(state => state?.reducer, shallowEqual);
  const [employeePhone, setEmployeePhone] = useState('');
  const [selectedKitchen, setSelectedKitchen] = useState('');
  const [kitchens, setKitchens] = useState(null);

  const handleLogin = () => {
    localStorage.setItem('selected_kitchen', selectedKitchen);
    // dispatch(setKitchen('SET_KITCHEN', selectedKitchen));
    // dispatch({
    //   type: 'FETCH_USER_PROFILE',
    //   payload: {
    //     _id: employeePhone
    //   }
    // });
    navigate('/home');
  };

  useEffect(() => {
    dispatch(getKitchens());
  }, []);

  console.log({selectedKitchen})

  return (
    <div
      className="h-screen min-h-[650px] p-8
    "
    >
      <div
        className="h-[90%] flex flex-col
    items-center justify-center"
      >
        <div className="mb-4">
          <img className="w-[60%] m-auto" src={cooking} alt="Food Products" />
        </div>
        <h1 className="text-[3.5rem] font-semibold">OneMirth</h1>
        <h3 className="text-[1rem] text-center mb-8 text-blackAccent">
          One stop food management for <br />
          tenants.
        </h3>
        <input type="text" placeholder="Enter employee phone" className="border border-solid rounded-md p-3" onChange={(e)=>setEmployeePhone(e.target.value)}/>
        <select onChange={(e) => setSelectedKitchen(e.target.value)} className="border border-solid rounded-md p-3 mt-3 w-3/4" >
          <option selected default>Select kitchen</option>
          {
            kitchensList?.map((d) => (
              <option value={d._id}>{d.name}</option>
            ))
          }
        </select>
        <SolidButton text={'Login'} classNames={'!w-2/3'} onClick={() => { handleLogin() }} />
        <a href="/sign-up" className="text-orange-10">Register Now</a>
      </div>

      <div className="h-[10%]">
        <p className="text-center text-[0.8rem] text-blackAccent">
          v1.0.0 | The Basic
        </p>
      </div>
    </div>
  );
}

export default LandingPage;
