import React from "react";
import Navbar from "../../components/BottomNavigation/Navbar";
import PageTitle from "../../components/PageTitle/PageTitle";
import SolidButton from "../../components/Buttons/SolidButton";

function ProfilePage() {
  return (
    <>
      <div className="min-h-[650px] px-8 py-4 pb-20">
        <PageTitle title={"Profile"} />

        <div className="px-6">
          <label className="text-blackAccent mb-6  flex flex-col">
            Name
            <input
              className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6
        "
            />
          </label>

          <label className="text-blackAccent mb-6  flex flex-col">
            Email
            <input
              className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6
        "
            />
          </label>

          <label className="text-blackAccent mb-6  flex flex-col">
            Birthday
            <input
              className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6
        "
              type="date"
            />
          </label>

          <label className="text-blackAccent mb-6  flex flex-col">
            Address
            <textarea
              className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[120px] mb-6
             
        "
            />
          </label>

          <label className="text-blackAccent mb-6  flex flex-col">
            PG No.
            <input
              className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6
             
        "
            />
          </label>

          <label className="text-blackAccent mb-6  flex flex-col">
            Room No.
            <input
              className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6
        "
            />
          </label>

          <SolidButton text={"Save"} />
        </div>
      </div>
      <Navbar />
    </>
  );
}

export default ProfilePage;
