import React from "react";
import SolidButton from "../../components/Buttons/SolidButton";
import { useNavigate } from "react-router-dom";

function SignUpPage() {
  const navigate = useNavigate();

  return (
    <div className="h-screen min-h-[650px] px-2 py-8">
      <h2 className="text-[1.75rem] text-center font-semibold text-blackAccent relative"
      >
        Sign Up
        <button
          className="absolute text-[1rem] right-4 top-[50%] translate-y-[-50%] text-orangeAccent font-normal"
          onClick={() => navigate("/")}
        >
          Login
        </button>
      </h2>

      <div className="p-8">
        <input
          className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6
        "
          placeholder="Username"
          style={{
            backgroundColor: "#E8E8E8",
          }}
        />

        <input
          className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6
        "
          placeholder="Email"
          style={{
            backgroundColor: "#E8E8E8",
          }}
        />

        <input
          className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6
        "
          placeholder="Employee Id"
          style={{
            backgroundColor: "#E8E8E8",
          }}
        />

        <select
          className="bg-[#F6F6F6] border-[#E8E8E8] border-[2px] w-[100%] px-4 py-2 outline-none rounded-lg text-blackAccent h-[48px] mb-6
        "
        >
          <option selected disabled value={""}>
            Select PG
          </option>
        </select>

        <div>
          <p className="text-[16px] text-[#666666] mb-2">Cooking Categories</p>
          <div className="flex flex-wrap">
            <label className="flex items-center text-[16px] w-[50%] mb-2">
              <input type="checkbox" className="mr-1" />
              Breakfast
            </label>

            <label className="flex items-center text-[16px] w-[50%] mb-2">
              <input type="checkbox" className="mr-1" />
              Lunch
            </label>

            <label className="flex items-center text-[16px] w-[50%] mb-2">
              <input type="checkbox" className="mr-1" />
              Dinner
            </label>

            <label className="flex items-center text-[16px] w-[50%] mb-2">
              <input type="checkbox" className="mr-1" />A La Carte
            </label>
          </div>
        </div>
      </div>

      <div className="p-8">
        {/* <label className="flex items-center">
          <input type="checkbox" className="mr-2" />{" "}
          <span>
            Accept
            <span className="text-orangeAccent"> Terms & Conditions</span>
          </span>
        </label> */}
        <SolidButton
          text={"Create Account"}
          onClick={() => navigate("/home")}
        />
      </div>
    </div>
  );
}

export default SignUpPage;
