import { callBackend } from "./service";

// fetch user data
export const fetchAllKitchens = async () => {
    return await callBackend("GET", `kitchen/get-kitchens-list`);
}

export const fetchTodaysOrders = async (kitchenId) => {
    return await callBackend("GET", `meal/get-todays-orders/${kitchenId}`);
}

export const updateOrderStatus = async (data) => {
    return await callBackend("PUT", `cook/update-order`, data);
}